import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import Callback from './pages/Callback/callback';
import Products from './pages/Products/Product';
import useAuth from './hooks/useAuth';
import Header from './components/header/Header';
import Ecwid from './pages/Ecwid/Ecwid';
import EcwidCallback from './pages/Ecwid/EcwidCallback';
import PrivacyPolicyPage from './pages/privacyPolicy/privacyPolicy';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  return (
    <>
      {!isLoginPage && <Header />}
      <main className="main-content">
        {children}
      </main>
      {/*  {!isLoginPage && <Footer />} */}
    </>
  );
};

function AppRouter() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/login" element={<Ecwid />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
          <Route path="/" element={<PrivateRoute><Products /></PrivateRoute>} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/ebay" element={<Home />} />
          <Route path="/ecwid/callback" element={<EcwidCallback />} />
          <Route path="*" element={<Navigate to="/products" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default AppRouter;
