import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

const PriceTypeSelector = ({ value, onChange }) => {
    return (
        <RadioGroup
            row
            value={value}
            onChange={onChange}
            name='priceType'
        >
            <FormControlLabel
                value="normal"
                control={<Radio />}
                label="Fixed"
                style={{ marginRight: '20px', whiteSpace: 'nowrap' }}
            />
            <FormControlLabel
                value="percentage"
                control={<Radio />}
                label="Percentage"
                style={{ marginRight: '20px', whiteSpace: 'nowrap' }}
            />
        </RadioGroup>
    );
};

PriceTypeSelector.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default PriceTypeSelector;
