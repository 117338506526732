import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

const CustomSwitch = ({ checked, onChange, label, sx, name }) => {
    return (
        <FormControlLabel
            control={<Switch checked={checked} onChange={onChange} />}
            label={label}
            name={name}
            sx={{
                "& .MuiTypography-root": {
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    marginLeft: "0px",
                    flexFlow: "wrap",
                    alignItems: 'center',
                },
                marginTop: "0px",
                marginBottom: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
                ...sx,  // Spread additional styles if provided
            }}
        />
    );
};

export default CustomSwitch;
