import React, { useState, useEffect } from 'react';
import {
  FormControl, Select, MenuItem, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import api from '../../utils/api';
import styles from "./categorySelect.module.css";
import { useNotification } from '../../contexts/NotificationContext';

const UniCategorySelection = ({ selectedCategory, setSelectedCategory, isPrimaryCategory }) => {
  const [categories, setCategories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchCategories(0);
  }, []);

  const fetchCategories = async (parentId) => {
    try {
      let cats = []
      if (parentId == 0 || !parentId) {
        cats = [{
          category_id: 0,
          category_name: "Select Category",
          have_child: false
        }]
      }
      if (selectedCategory) {
        let category = await api.get(`/ebay/category/${selectedCategory}`);
        cats = category.data
      }

      const response = await api.get(`/ebay/categories/${parentId}`);
      let mergeArreay = [...cats, ...response.data]
      setCategories(mergeArreay);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const handleCategoryChange = (event) => {
    const selectedCatId = event.target.value;
    if (selectedCatId == isPrimaryCategory) {
      showNotification('Please select different category', 'error')
      return false;
    }
    setSelectedCategory(selectedCatId);
    setDropdownOpen(false);
  };

  const clearSelection = async () => {
    await setSelectedCategory(null);
    fetchCategories(0);
  };

  const renderCategories = (categories) => {
    return categories.map(cat => (
      <MenuItem
        key={cat.category_id}
        value={cat.category_id}
        onClick={(e) => {
          if (cat.have_child) {
            e.stopPropagation();
            fetchCategories(cat.category_id);
            setDropdownOpen(true);
          }
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {!cat.have_child && cat.category_name}
          {cat.have_child && dropdownOpen && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                fetchCategories(cat.category_id);
                setDropdownOpen(true);
              }}
            >
              {cat.category_name}
              <ExpandMoreIcon />
            </IconButton>
          )}
        </div>
      </MenuItem>
    ));
  };

  return (
    <>
      <FormControl fullWidth>
        <Select
          value={selectedCategory ?? 0}
          onChange={handleCategoryChange}
          open={dropdownOpen}
          onClose={() => setDropdownOpen(false)}
          onOpen={() => setDropdownOpen(true)}
          className={styles.multiSelectStyles}
        >
          {renderCategories(categories)}
        </Select>
        {selectedCategory && (
          <IconButton onClick={clearSelection} size="small" className={styles.categoryClearIcon}>
            <ClearIcon />
          </IconButton>
        )}
      </FormControl>
    </>

  );
};

export default UniCategorySelection;
