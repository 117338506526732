import React, { createContext, useState, useContext } from 'react';
import Notification from '../components/notification/Notification';

// Create the context
const NotificationContext = createContext(undefined);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const showNotification = (message , severity) => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <NotificationContext.Provider value={{ showNotification, handleCloseNotification }}>
      {children}  {/* Ensure children are being rendered */}
      <Notification
        message={notification.message}
        severity={notification.severity}
        open={notification.open}
        handleCloseNotification={handleCloseNotification}
      />
    </NotificationContext.Provider>
  );
};

// Create a custom hook for using the context
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
