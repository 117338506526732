// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Fetch user details using the token or set the user state directly
      setUser({ token }); // Replace this with actual user fetching logic
    }
    setLoading(false); // Set loading to false once user state is determined
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
