import React, { useState, useEffect } from 'react';
import { Modal, Button, Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import styles from './richTextEditorModal.module.css'; // Adjust the path as needed

const RichTextEditorModal = ({ open, onClose, initialValue, onSubmit, title }) => {
  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    setEditorValue(initialValue);
  }, [initialValue]);

  const handleSubmit = () => {
    onSubmit(editorValue);
    onClose(); // Close the modal after submitting
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.richEditorModal}>
      <Box className={styles.modalContent}>
        <h2 className={styles.modalMainHeading} >Edit Description - {title}</h2>
        <ReactQuill
          value={editorValue}
          onChange={setEditorValue}
          className={styles.richTextEditor}
        />
        <Box className={styles.buttonContainer}>
          <Button onClick={onClose} variant="contained" className={styles.cancelBtns}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RichTextEditorModal;
