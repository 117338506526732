import React from 'react';
import { Modal as MUIModal, Box, CircularProgress } from '@mui/material';
import styles from "./Modal.module.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "900px",
  maxWidth: "30%",
  maxHeight: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: "hidden",
  p: 4,
};

const Modal = ({ title, children, modalOpen, modalHandleClose }) => {
  return (
    <MUIModal
      open={modalOpen}
      onClose={modalHandleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-title" variant="h6" component="h2" className={styles.modalGeneralTitles}>
          {title}
        </h2>
        <div id="modal-description" sx={{ mt: 2 }} style={{ height: "100%", overflowY: "auto" }}>
          {children}
        </div>

      </Box>
    </MUIModal>
  );
};

export default Modal;
