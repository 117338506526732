import React from 'react';
import styles from './Login.module.css';
import axios from 'axios';


const EcwidLoginButton = () => {
  const handleLogin = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/ecwid_login`);
    /* window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/ecwid_login`; */
    window.location.href = response.data.url;
  };

  

  return (
    <div className={styles.container}>
      {<div  className={styles.form}>
        <h2 className={styles.mainHeadings}>Login</h2>
        <button type="submit" className={styles.loginBtn} onClick={handleLogin}>Login With Ecwid</button>
      </div>}
    </div>
  );
};

export default EcwidLoginButton;
