export function calculatePriceAndPercentage(value, actualAmount, type) {
  let price = value;
  let percentage = value;

  if (actualAmount === 0) {
    percentage = value;
    price = value;
  }
  if (type === 'percentage' && actualAmount !== 0) {
    price = ((actualAmount * value) / 100).toFixed(2);
    percentage = value;
  } else if (actualAmount !== 0) {
    price = value;
    percentage = ((value * 100) / actualAmount).toFixed(2);
  }
  return { percentage, price };
}


export function priceRegex(value) {
  return /^\d*(\.\d{0,2})?$/.test(value)
}