import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { useNotification } from '../../contexts/NotificationContext';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EcwidCallback = () => {
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();

  const query = useQuery();
  const code = query.get('code');
  const storeId = query.get('store_id');
  const isFetchingRef = useRef(false);
  
  const navigate = useNavigate();
  const { setUser } = useAuth();

  useAuthRedirect();

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (code && storeId && !isFetchingRef.current) {
        isFetchingRef.current = true;  // Prevents duplicate requests
        setLoading(true);  // Start the loader
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/callback`, {
            params: { code, store_id: storeId },
          });

          const access_token= response.data.data.access_token
          
          if (access_token) {
            localStorage.setItem('token', access_token);
            setUser({ token: access_token });  
            showNotification('Login successful! Redirecting...', 'success');
            setLoading(false);
            navigate('/');
          }
        
          
        } catch (error) {
          setLoading(false);
          const message = error.response?.data?.message || 'Failed to log in. Please try again.';
          console.error('Failed to retrieve access token:', error);
          showNotification(message, 'error');
          setTimeout(() => navigate('/login'), 5000);

        }
      }
    };

    fetchAccessToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, storeId]);

  return (
    <div>
      {loading ? <div>Loading...</div> : <div>Logging you in...</div>}
      
    </div>
  );
};

export default EcwidCallback;
