import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request interceptor to add Authorization header
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 status code and other error responses
api.interceptors.response.use(
  response => response,
  error => {
    console.error('Error in response interceptor:', error);
    if (error.response) {
      if(error.response?.status === 400 && error.response.data?.message === '1001'){
        window.location.href = '/ebay';
      }
      if (error.response.status === 401) {
        // Clear the token and redirect to the login page
        localStorage.removeItem('token');
        window.location.href = '/login';
      } else if (error.response.status < 200 || error.response.status >= 300) {
        // Throw an error with the status code and message
        const message = error.response.data?.message || 'An error occurred';
        throw new Error(`${message}`);
      }
    } else {
      // Handle case where there is no response (e.g., network error)
      throw new Error('No response received');
    }

    return Promise.reject(error);
  }
);

export default api;
