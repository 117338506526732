// src/App.js
import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import AppRouter from './Router';
import './App.css';
import { NotificationProvider } from './contexts/NotificationContext';

function App() {
  return (
    <AuthProvider>
      <NotificationProvider>
        <div className="app-container">
          <AppRouter />
        </div>
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
