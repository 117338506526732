import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Button, Container, Typography,
  TextField, Pagination, Grid, InputAdornment,
  Checkbox, Select, MenuItem, FormControl, InputLabel, CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from '../../utils/api';
import noImage from '../../assets/images/noImage.png';
import styles from './product.module.css';
import EditProductDialog from './EditProductDialog';
import MultiSelectDialog from './MultiSelectDialog';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useNotification } from '../../contexts/NotificationContext';
import Tooltip from '@mui/material/Tooltip';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductKeys, setSelectedProductKeys] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10); // State to track items per page
  const [selectMode, setSelectMode] = useState('single');
  const [multiSelectOpen, setMultiSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const { showNotification } = useNotification();

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);  // Start the loader

      const response = await api.get('/ecwid/products/', {
        params: {
          offset,
          limit,
          query: searchQuery
        }
      });
      setLoading(false);
      setProducts(response.data.items);
      setTotal(response.data.total);
    } catch (error) {
      console.error("There was an error fetching the product data!", error);
    }
  }, [offset, limit, searchQuery]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleClickOpen = (product) => {
    if (!product.sku || product.sku === "" || product.sku === null) {
      showNotification('add SKU in ecwid.', 'error');
      return
    }

    for (let comb of product.combinations) {
      if (comb.sku == null) {
        showNotification('Please add SKU in the product(s) in ECWID.', 'error');
        return
      }
    }
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    setSelectedProduct(null);
    setLoading(true);
    fetchProducts()
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setOffset(0);
  };

  const handlePageChange = (event, value) => {
    setOffset((value - 1) * limit);
    window.scrollTo(0, 0);
  };

  const handleSelectProduct = async (productId) => {
    handleSelectProductKey(productId)
    const selectedProduct = products.find(product => product.id === productId);

    // Validate SKU before selecting the product
    if (!selectedProduct.sku || selectedProduct.sku === "") {
      showNotification(`Please add SKU in the product(s) in ECWID.`, 'error');
      return;
    }

    setSelectedProducts(prevSelected => {
      const isProductSelected = prevSelected.some(product => product.id === productId);
      if (isProductSelected) {
        // Remove product from selected
        return prevSelected.filter(product => product.id !== productId);
      } else {
        // Add product to selected
        return [...prevSelected, selectedProduct];
      }
    });
  };

  const handleSelectAllProducts = (event) => {
    handleSelectAllProductKeys(event);
    if (event.target.checked) {
      let variantCount = 0

      const newSelectedProducts = products.filter((product) => {
        // Check if SKU exists and it's not a Variant type
        if (!product.sku || product.sku === "" || product.sku === null) {
          showNotification('Please add SKU in the product(s) in ECWID.', 'error');
          return false;
        }

        // Exclude Variant products from being selected but count them towards the limit
        if (product.product_type === "Variant") {
          variantCount++
          return false; // Don't add Variant products
        }

        return true;
      }).slice(0, 10 - variantCount); // Ensure only 10 products are selected

      setSelectedProducts(prevSelected => [
        ...prevSelected,
        ...newSelectedProducts.filter(p => !prevSelected.some(selected => selected.id === p.id))
      ]);
    } else {
      const selectedProductIds = new Set(products.map(product => product.id));
      setSelectedProducts(prevSelected => prevSelected.filter(product => !selectedProductIds.has(product.id)));
    }
  };

  const handleSelectProductKey = async (productId) => {
    const selectedProduct = products.find(product => product.id === productId);
    if (!selectedProduct.sku || selectedProduct.sku === "") {
      return;
    }

    let newSelectedProductKeys = { ...selectedProductKeys };
    let newSelectedCount = selectedCount;

    // Toggle the main product selection
    if (newSelectedProductKeys[productId]) {
      delete newSelectedProductKeys[productId];
      newSelectedCount -= 1;
    } else {
      newSelectedProductKeys[productId] = true;
      newSelectedCount += 1;
    }

    // Handle combinations
    for (let comb of selectedProduct.combinations) {
      if (newSelectedProductKeys[comb.id] && !newSelectedProductKeys[productId]) {
        delete newSelectedProductKeys[comb.id];
        newSelectedCount -= 1;
      } else if (newSelectedProductKeys[productId]) {
        newSelectedProductKeys[comb.id] = true;
        newSelectedCount += 1;
      }
    }

    // Update state in one go
    setSelectedProductKeys(newSelectedProductKeys);
    setSelectedCount(newSelectedCount);
  };

  const handleSelectAllProductKeys = (event) => {
    if (event.target.checked) {
      const newSelected = {};
      let count = 0;

      products.forEach(product => {
        if (!product.sku || product.sku === "" || product.sku === null) {
          showNotification('Please add SKU in the product(s) in ECWID.', 'error');
          return;
        }
        if (count < 10) { // Ensure only 10 products are selected
          newSelected[product.id] = true;
          count++;
        } else {
          showNotification('You can only select up to 10 products.', 'warning');
        }
      });

      setSelectedProductKeys(prevSelected => ({
        ...prevSelected,
        ...newSelected
      }));
      setSelectedCount(count); // Set the count of selected products

    } else {
      const newSelected = { ...selectedProducts };
      products.forEach(product => {
        delete newSelected[product.id];
      });
      setSelectedProductKeys(newSelected);
      setSelectedCount(0); // Reset the count when unselecting all
    }
  };


  const handleProductUpdate = (updatedProduct) => {
    setProducts(products.map(product => product.id === updatedProduct.id ? updatedProduct : product));
  };

  const handleSelectModeChange = (event) => {
    setSelectMode(event.target.value);
    setSelectedProduct(null);
    setSelectedProducts([]);
    setSelectedProductKeys({});
  };

  const handleMultiSelectOpen = () => {
    setMultiSelectOpen(true);

  };

  const handleMultiSelectClose = () => {
    setMultiSelectOpen(false);
    setSelectedProducts([]);
    setSelectedProductKeys({});
    setLoading(true);
    fetchProducts()
    setSelectedCount(0);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0);
  };

  return (
    <div>
      {<Container className={styles.productSyncContainer}>
        <Typography variant="h4" component="h1" gutterBottom className={styles.mainHeadings}>
          Products
        </Typography>
        <Grid container alignItems="center" className={styles.searchBoxSytles}>
          <Grid item className={styles.searchField}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Product name, SKU or UPC"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleSearchChange({ target: { value: '' } })}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                )
              }}
            />

          </Grid>
          <Grid item className={styles.searchBoxes}>
            {(selectMode === 'multi') && <Grid item className={styles.searchBtn}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMultiSelectOpen}
                disabled={Object.keys(selectedProductKeys).length === 0}
              >
                {'View Selected'}
              </Button>
            </Grid>}
            <Grid item className={styles.selectModeBox}>
              <FormControl variant="outlined" className={styles.selectModeControl}>
                <InputLabel>Select Mode</InputLabel>
                <Select
                  value={selectMode}
                  onChange={handleSelectModeChange}
                  label="Select Mode"
                  className={styles.filterSelectbox}
                >
                  <MenuItem value="single">Single Select</MenuItem>
                  <MenuItem value="multi">Multi Select</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          <Table className={styles.productTable}>
            <TableHead>
              <TableRow>
                {selectMode === 'multi' && (
                  <TableCell padding="checkbox" className={styles.productTableCheckbox}>
                    <Checkbox
                      checked={
                        products.length > 0 &&
                        products.every(product =>
                          selectedProducts.some(selectedProduct => selectedProduct.id === product.id)
                        )
                      }
                      onChange={handleSelectAllProducts}
                    />
                  </TableCell>
                )}
                <TableCell className={styles.productTableImage}>Image</TableCell>
                <TableCell className={styles.productTableName}>Name</TableCell>
                <TableCell className={styles.productTableInStock}>Stock In Ecwid</TableCell>
                <TableCell className={styles.productTableSKU}>SKU</TableCell>
                <TableCell className={styles.productTablePrice}>Price</TableCell>
                <TableCell className={styles.productTableStatus}>Status</TableCell>
                <TableCell className={styles.productTableProductType}>Product Type</TableCell>
                <TableCell className={styles.productTableCategory}>Category</TableCell>
                {selectMode === 'single' && <TableCell className={styles.productTableAction}>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={selectMode === 'multi' ? 9 : 10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                products.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={selectMode === 'multi' ? 9 : 10} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                ) : (
                  products.map((product) => (
                    <React.Fragment key={product.id}>
                      <TableRow
                        style={{
                          backgroundColor:
                            product.combinations?.length > 0
                              ? '#ebeef0'
                              : product.is_variant
                                ? '#ebeef061'
                                : 'white'
                        }}
                      >
                        {selectMode === 'multi' && (
                          <TableCell padding="checkbox" className={styles.productTableCheckbox}>
                            <Tooltip
                              title={
                                selectedCount >= 10 && !selectedProductKeys[product.id]
                                  ? "Limit exceeded. You can sync up to 10 products at a time."
                                  : ""
                              }
                            >
                              <span>
                                <Checkbox
                                  checked={!!selectedProductKeys[product.id]}
                                  onChange={() => handleSelectProduct(product.id)}
                                  disabled={selectedCount >= 10 && !selectedProductKeys[product.id]}
                                />
                              </span>
                            </Tooltip>
                          </TableCell>
                        )}
                        <TableCell className={styles.productTableImage}>
                          <div className={styles.imageContainer}>
                            {product.thumbnailUrl ? (
                              <img src={product.thumbnailUrl} alt={product.name} className={styles.image} />
                            ) : (
                              <img src={noImage} alt={product.name} className={styles.image} />
                            )}
                            <span
                              className={`${styles.stockBadge} ${product.quantity > 0 ? styles.inStock : styles.outOfStock}`}
                              style={{ top: product?.ebay_sync_data?.is_sync ? "38px" : "8px" }}
                            >
                              {product.quantity > 0 ? 'In Stock' : 'Out of Stock'}
                            </span>
                            {product?.ebay_sync_data?.is_sync && (
                              <span className={`${styles.syncBadge}`}>
                                Synced
                              </span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '200px',
                            maxWidth: '200px',
                            wordWrap: 'break-word',
                            whiteSpace: 'initial'
                          }}
                          className={styles.productTableName}
                        >
                          {product.name}
                        </TableCell>
                        <TableCell className={styles.productTableInStock}>{product.quantity ?? 'N/A'}</TableCell>
                        <TableCell className={styles.productTableSKU}>{product.sku !== "" ? product.sku : 'N/A'}</TableCell>
                        <TableCell className={styles.productTablePrice}>{product.defaultDisplayedPriceFormatted ?? 'N/A'}</TableCell>
                        <TableCell className={styles.productTableStatus}>{product?.ebay_sync_data.is_sync ? 'Synced' : 'Not Synced'}</TableCell>
                        <TableCell className={styles.productTableProductType}>{product.product_type ?? 'N/A'}</TableCell>
                        <TableCell className={styles.productTableCategory}>
                          {product.categoryName ?? 'N/A'}
                        </TableCell>
                        {selectMode === 'single' && (
                          <TableCell className={styles.productTableAction}>
                            <Tooltip title={product.ebay_sync_data.is_parent_sync ? "This Variation must sync with parent as it's parent is already sync" : ""}>
                              <span>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClickOpen(product)}
                                  disabled={product.ebay_sync_data.is_parent_sync}
                                >
                                  Edit
                                </Button>
                              </span>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    </React.Fragment>
                  ))
                )
              )}
            </TableBody>

          </Table>
        </TableContainer>
        <Grid container alignItems="center" className={styles.productPaginations}>
          <Grid item className={styles.productPag}>
            {products.length > 0 && (
              <Grid container justifyContent="center" sx={{ my: 2 }}>
                <Pagination
                  count={Math.ceil(total / limit)}
                  page={Math.ceil(offset / limit) + 1}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Grid>
            )}
          </Grid>
          <Grid item className={styles.productsPerPage}>
            <FormControl variant="outlined" className={styles.selectLimitControl}>
              <InputLabel>Items per Page</InputLabel>
              <Select
                value={limit}
                onChange={handleLimitChange}
                label="Items per Page"
                className={styles.perPageSelectbox}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {selectedProduct && (
          <EditProductDialog
            open={open}
            handleClose={handleClose}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            handleFormSubmit={handleFormSubmit}
            handleProductUpdate={handleProductUpdate}
          />
        )}
        {selectMode === 'multi' && (
          <MultiSelectDialog
            open={multiSelectOpen}
            handleClose={handleMultiSelectClose}
            selectedProducts={selectedProducts}
          />
        )}
      </Container>}
    </div>

  );
};

export default Products;
