import React from 'react';
import styles from './privacyPolicy.module.css'; // Add your CSS module or use inline styles

const PrivacyPolicyPage = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Privacy Policy</h1>
      <p>
        Effective Date: 01-09-2024
      </p>
      <p>
        At Charles Ecwid eBay, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application. Please read this privacy policy carefully.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        We do not collect personal information such as your name, address, email, or phone number. The only data we access is related to inventory management and is used solely for that purpose.
      </p>
      <h2>2. How We Use Your Information</h2>
      <p>
        The information we access is used to manage and update your inventory. We do not use your data for any other purpose.
      </p>
      <h2>3. Data Sharing</h2>
      <p>
        We do not share your data with third parties. All data is kept confidential and used exclusively for managing your inventory.
      </p>
      <h2>4. Security</h2>
      <p>
        We use appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <h2>5. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p>
        Charles Ecwid eBay<br />
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
