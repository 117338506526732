import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../utils/api';

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthorizationCode = async () => {
      const queryParams = new URLSearchParams(location.search);
      const authorizationCode = queryParams.get('code');

      if (authorizationCode) {
        try {
          const response = await api.post('/ebay', { code: authorizationCode });
          console.log(response.data);
          navigate('/');
        } catch (error) {
          console.error('Error fetching authorization code:', error);
          navigate('/ebay');
        }
      }
    };

    fetchAuthorizationCode();
  }, [location]);

  return (
    <div className="Callback">
      <header className="Callback-header">
        <h1>Authorization Callback</h1>
        <p>Processing authorization code...</p>
      </header>
    </div>
  );
};

export default Callback;
