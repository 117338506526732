import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Grid, Switch, InputLabel, CircularProgress
} from '@mui/material';
import styles from "./product.module.css";
import Modal from "../../components/common/modal/Modal";
import ClearIcon from '@mui/icons-material/Clear';
import RichTextEditor from '@mantine/rte';
import UniCategorySelection from '../../components/common/singleCategorySelect';
import api from '../../utils/api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNotification } from '../../contexts/NotificationContext';
import { priceRegex, calculatePriceAndPercentage } from '../../utils/pricing';
import noImage from '../../assets/images/noImage.png';
import Tooltip from '@mui/material/Tooltip';
import CustomSwitch from '../../components/common/toggle/toggle';
import PriceTypeSelector from '../../components/common/radioGroup/PriceTypeRadioGroup';


const EditProductDialog = ({
  open, handleClose, selectedProduct, setSelectedProduct
}) => {
  const [editFields, setEditFields] = useState({
    name: false,
    price: false,
    quantity: false,
    description: false,
  });
  const [selectedCategory, setSelectedCategory] = useState(selectedProduct.ebay_sync_data.category);
  const [selectedSecondaryCategory, setSecondarySelectedCategory] = useState(selectedProduct.ebay_sync_data.secondary_category);
  const [combinations, setCombinations] = useState([]);
  const [isSync, setIsSync] = useState(selectedProduct.is_sync);
  const [isToggleSet, setIsToggleSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setconfirmationModal] = useState(false);
  const { showNotification } = useNotification();
  const [toggleState, setToggleState] = useState({}); // Manage toggles for each field

  useEffect(() => {
    if (selectedCategory && selectedCategory != null) {
      console.log(selectedCategory);
      handleCategoryChange(selectedCategory);
    }
  }, [])

  useEffect(() => {
    if (open) {
      if (selectedProduct.ebay_sync_data.category) {
        setSelectedCategory(selectedProduct.ebay_sync_data.category);
      }
      if (selectedProduct.ebay_sync_data.combinations) {
        setCombinations(selectedProduct.ebay_sync_data.combinations);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedProduct]);

  useEffect(() => {
    if (selectedProduct.price !== selectedProduct.ebay_sync_data.price) {
      editFields.price = true
    }
    if (selectedProduct.quantity !== selectedProduct.ebay_sync_data.quantity) {
      editFields.quantity = true
    }
    if (selectedProduct.description !== selectedProduct.ebay_sync_data.description) {
      editFields.description = true
    }
    if (selectedProduct.name !== selectedProduct.ebay_sync_data.name) {
      editFields.name = true
    }
    setIsToggleSet(true)


    setEditFields({ ...editFields })
    const initialToggleState = {};
    selectedProduct.ebay_sync_data.combinations?.forEach((combination, combinationIndex) => {
      initialToggleState[`${combinationIndex}-quantity`] = combination.quantity !== combination.ebay_sync_data.quantity;
      initialToggleState[`${combinationIndex}-name`] = combination.name !== combination.ebay_sync_data.name;
    });
    setToggleState(initialToggleState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggleSet]);


  const handleSyncToggle = () => {
    setIsSync(!isSync);
    for (let comb = 0; comb < selectedProduct.combinations.length; comb++) {
      setCombinations(prevCombinations => {
        const newCombinations = [...prevCombinations];
        newCombinations[comb].ebay_sync_data.is_sync = !isSync;
        return newCombinations;
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'price' || name === 'percentage') {
      let { price, percentage } = calculatePriceAndPercentage(value, selectedProduct.price, selectedProduct.ebay_sync_data.priceType)
      //const finalPrice = selectedProduct.ebay_sync_data.priceType === 'percentage' ? (selectedProduct.price * value / 100).toFixed(2) : value;
      setSelectedProduct({
        ...selectedProduct,
        ebay_sync_data: { ...selectedProduct.ebay_sync_data, price, percentage }
      });
    } else if (name === 'quantity' && selectedProduct.quantity < value) {
      if (/^\d*\.?\d*$/.test(value)) {
        setSelectedProduct({
          ...selectedProduct,
          ebay_sync_data: { ...selectedProduct.ebay_sync_data, quantity: selectedProduct.quantity }
        });
      }
    } else {
      setSelectedProduct({
        ...selectedProduct,
        ebay_sync_data: { ...selectedProduct.ebay_sync_data, [name]: value }
      });
    }
  };

  const handleEditorChange = (value) => {
    setSelectedProduct({
      ...selectedProduct,
      ebay_sync_data: { ...selectedProduct.ebay_sync_data, description: value, category: selectedCategory }
    });
  };

  const handleConfirmationOpen = () => {
    setconfirmationModal(true);
  }

  const handleConfirmationClose = () => {
    setconfirmationModal(false);
  }

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();


    try {
      const updatedProduct = {
        ...selectedProduct,
        is_sync: isSync,
        ebay_sync_data: {
          ...selectedProduct.ebay_sync_data,
          combinations,
          category: selectedCategory,
          secondary_category: selectedSecondaryCategory,
          is_sync: isSync
        }
      };

      if (!(editFields.quantity)) {
        updatedProduct.ebay_sync_data.quantity = selectedProduct.quantity
      }
      if (!(editFields.price)) {
        updatedProduct.ebay_sync_data.price = selectedProduct.price
      }
      if (!(editFields.name)) {
        updatedProduct.ebay_sync_data.name = selectedProduct.name
      }
      if (!(editFields.description)) {
        updatedProduct.ebay_sync_data.description = selectedProduct.description
      }

      await api.post('/ebay/product', { productData: [updatedProduct] });
      showNotification(`Product(s) data has been saved ${isSync ? 'and synced' : ''} successfully!.`, 'success');
      setSelectedProduct(updatedProduct);
      handleClose();

    } catch (error) {
      showNotification(error.message ?? `There was an error updating the product!.`, 'error');
    } finally {
      setLoading(false);
    }

    handleConfirmationClose();
  };


  const handleToggleField = (field) => {
    setEditFields({ ...editFields, [field]: !editFields[field] });
    if (selectedProduct.price == 0 && field == 'price' && editFields.price) {

      setSelectedProduct({
        ...selectedProduct,
        ebay_sync_data: { ...selectedProduct.ebay_sync_data, price: selectedProduct.price }
      });
    }
  };

  const handleCategoryChange = async (category) => {
    const response = await api.get(`/ebay/category-specifics/${category}`);
    const aspects = response.data.requiredAspects.reduce((acc, item) => {
      acc[item.name] = ['N/A'];
      return acc;
    }, {});
    console.log(category)
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      ebay_sync_data: {
        ...prevProduct?.ebay_sync_data,
        category: category,
        aspects: aspects
      },
    }));

  };

  const handleToggleCombinationField = (field, index) => {
    setToggleState((prevState) => ({
      ...prevState,
      [`${index}-${field}`]: !prevState[`${index}-${field}`]
    }));
    setCombinations(prevCombinations => {
      const newCombinations = [...prevCombinations];
      const combination = newCombinations[index];
      combination.ebay_sync_data[field] = combination[field];
      return newCombinations;
    });
  };

  const validateFields = () => {
    if (!isSync) {
      return true;
    }
    const { name, price, quantity, description } = selectedProduct.ebay_sync_data;
    return name && price > 0 && quantity > 0 && description && selectedCategory !== null;
  };

  const handleCombinationChange = (index, field, value) => {
    const updatedCombinations = [...combinations];
    let finalValue = value;
    if (field === 'price') {
      let { price, percentage } = calculatePriceAndPercentage(value, updatedCombinations[index].price, updatedCombinations[index].ebay_sync_data.priceType)
      updatedCombinations[index] = {
        ...updatedCombinations[index],
        ebay_sync_data: {
          ...updatedCombinations[index].ebay_sync_data,
          percentage, price, priceType: updatedCombinations[index].ebay_sync_data.priceType
        },
      };
    } else {
      if (field === 'quantity' && updatedCombinations[index].quantity < value) {
        finalValue = updatedCombinations[index].quantity;
      }

      updatedCombinations[index] = {
        ...updatedCombinations[index],
        ebay_sync_data: {
          ...updatedCombinations[index].ebay_sync_data,
          [field]: finalValue,
        },
      };
    }
    if (field === 'quantity' && updatedCombinations[index].quantity < value) {
      finalValue = updatedCombinations[index].quantity;
    }

    setCombinations(updatedCombinations);

    setSelectedProduct(prev => ({
      ...prev,
      ebay_sync_data: { ...prev.ebay_sync_data, combinations: updatedCombinations }
    }));
  };

  const handlePriceTypeChange = (e) => {
    setSelectedProduct({
      ...selectedProduct,
      ebay_sync_data: { ...selectedProduct.ebay_sync_data, priceType: e.target.value }
    });
  };

  const handleToggleSync = (index) => {
    setCombinations(prevCombinations => {
      const newCombinations = [...prevCombinations];
      newCombinations[index].ebay_sync_data.is_sync = !newCombinations[index].ebay_sync_data.is_sync;
      return newCombinations;
    });
  };

  const renderCombinations = () => {
    if (combinations.length > 0) {
      return combinations.map((combination, index) => (
        <Grid container key={index} className={styles.editProductSKUs}>
          <Grid item xs={8} className={styles.productSKUHeading}>
            <InputLabel>
              <b>SKU:</b> {combination.sku}
            </InputLabel>
          </Grid>

          {/* Toggle Sync Button */}
          <Grid item xs={4} className={styles.rightAlign}>
            <b>Sync Status: {combination.ebay_sync_data.is_sync ? <span style={{ color: 'green' }}>Synced</span> : <span style={{ color: 'red' }}>Not Synced</span>}</b>
            <Switch
              checked={combination.ebay_sync_data.is_sync}
              onChange={(e) => handleToggleSync(index, e.target.value)}
              color="primary"
            />
          </Grid>
          {/* Image Field */}
          <Grid item xs={12} className={styles.colLeft} >
            <Grid container spacing={2} style={{ display: "flex", gap: "0px 10px", flexWrap: "wrap", margin: "0px" }}>
              <div key={index} style={{ display: 'inline-block', position: 'relative' }}>
                <img src={combination.thumbnailUrl ?? noImage} alt={`Product ${index}`} style={{ width: '50px', height: '50px' }} />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} className={styles.colLeft} >
            <Tooltip title={combination.ebay_sync_data.name || ''} placement="top">
              <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
                <Grid item xs={9} className={styles.fullWidthOnMobile}>
                  <InputLabel><b><span className={styles.textRed}>*</span> Variant Name: </b><span className={styles.lightText}>{combination.name}</span></InputLabel>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end" className={styles.fullWidthOnMobile}>
                  <CustomSwitch
                    checked={toggleState[`${index}-name`]}
                    onChange={(e) => handleToggleCombinationField('name', index)}
                    label="Name"
                  />
                </Grid>
              </Grid>
              {toggleState[`${index}-name`] && (
                <TextField
                  fullWidth
                  name="name"
                  value={combination.ebay_sync_data.name}
                  onChange={(e) => handleCombinationChange(index, 'name', e.target.value)}
                  placeholder="Enter Variant name"
                  className={styles.fieldStyles}
                  inputProps={{ maxLength: 80 }}
                />
              )}
            </Tooltip>
          </Grid>

          <Grid item xs={6} className={styles.colLeft}>
            <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
              <Grid item xs={6} className={styles.fullWidthOnMobile}>
                <InputLabel><b><span className={styles.textRed}>*</span> Variant Quantity: </b><span className={styles.lightText}>{combination.quantity}</span></InputLabel>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end" className={styles.fullWidthOnMobile}>
                <CustomSwitch
                  checked={toggleState[`${index}-quantity`]}
                  value={combination.ebay_sync_data.quantity}
                  onChange={(e) => handleToggleCombinationField('quantity', index)}
                  label="Alternative Quantity"
                />
              </Grid>
            </Grid>
            {toggleState[`${index}-quantity`] && (
              <TextField
                fullWidth
                name="quantity"
                value={combination.ebay_sync_data.quantity}
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^\d*$/.test(value)) {
                    handleCombinationChange(index, 'quantity', e.target.value);
                  }
                }}
                placeholder="Enter product quantity"
                className={`${styles.fieldStyles}`}
                inputProps={{ maxLength: 6 }}
              />
            )}
          </Grid>
          <Grid item xs={6} className={styles.colRight} style={{ paddingTop: '12px' }}>
            <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
              <Grid item xs={9} className={styles.fullWidthOnMobile}>
                <InputLabel><b><span className={styles.textRed}>*</span> Variant Price: </b><span className={styles.lightText}>{combination.price}</span></InputLabel>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              label={'Ebay Price'}
              value={selectedProduct.ebay_sync_data.price}
              disabled={true}
              className={styles.editProductPrices}
            />
          </Grid>
        </Grid>
      ));
    }
    return null;
  };


  return (
    <div>
      {<Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle className={styles.modalMainHeading}>Edit Product - {selectedProduct.name}</DialogTitle>
        <DialogContent>
          <form className={styles.editProductForm}>
            <Grid container className={styles.editProductContainer}>
              <Grid item xs={12} className={styles.fieldRow}>
                <InputLabel className={styles.productSyncSwitch}>
                  Sync Status: {isSync ? <span style={{ color: 'green' }}>Synced</span> : <span style={{ color: 'red' }}>Not Synced</span>}
                  <Switch
                    checked={isSync}
                    onChange={handleSyncToggle}
                    color="primary"
                  />
                </InputLabel>
                <Grid item xs={12} className={styles.productSKUHeading}>
                  <InputLabel><b>SKU:</b> {selectedProduct.sku}</InputLabel>
                </Grid>
              </Grid>
              <Grid item xs={12} className={`${styles.fieldsBox} ${styles.categoryFieldsBox}`}>
                <Grid item xs={12} className={styles.fullWidthOnMobile}>
                  <InputLabel><b>Ecwid Category: </b><span className={styles.lightText}>{selectedProduct.categoryName}</span></InputLabel>
                </Grid>
                <Grid item xs={12} className={styles.colLeft}>
                  <InputLabel><b><span className={styles.textRed}>*</span> Primary Product Category</b></InputLabel>
                  <UniCategorySelection
                    selectedCategory={selectedCategory}
                    setSelectedCategory={(category) => {
                      setSelectedCategory(category);
                      handleCategoryChange(category);
                    }}
                    isPrimaryCategory={selectedSecondaryCategory}
                  />
                </Grid>
                <Grid item xs={12} className={styles.colRight}>
                  <InputLabel><b>Secondary Product Category </b></InputLabel>
                  <UniCategorySelection setSelectedCategory={(category) => {
                    setSelectedCategory(category);
                    handleCategoryChange(category);
                  }} isPrimaryCategory={selectedCategory} />
                </Grid>
              </Grid>

              <Grid item xs={12} className={styles.fieldsBox}>
                <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
                  <Grid item xs={9} className={styles.fullWidthOnMobile}>
                    <InputLabel><b><span className={styles.textRed}>*</span> Product Name: </b><span className={styles.lightText}>{selectedProduct.name}</span></InputLabel>
                  </Grid>
                  <Grid item xs={3} container justifyContent="flex-end" className={styles.fullWidthOnMobile}>
                    <CustomSwitch
                      checked={editFields.name}
                      onChange={() => handleToggleField('name')}
                      label="Alternative Name"
                    />
                  </Grid>
                </Grid>
                {editFields.name && (
                  <TextField
                    fullWidth
                    name="name"
                    value={selectedProduct.ebay_sync_data.name}
                    onChange={handleInputChange}
                    placeholder="Enter product name"
                    className={styles.fieldStyles}
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              </Grid>
              <Grid container spacing={0} xs={12} className={styles.fieldsBox}>
                <Grid item xs={6} className={styles.colLeft}>
                  <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
                    <Grid item xs={6} className={styles.fullWidthOnMobile}>
                      <InputLabel><b><span className={styles.textRed}>*</span> Product Price: </b><span className={styles.lightText}>{selectedProduct.defaultDisplayedPriceFormatted}</span></InputLabel>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" className={styles.fullWidthOnMobile}>
                      <CustomSwitch
                        checked={editFields.price}
                        onChange={() => handleToggleField('price')}
                        label="Alternative Price"
                      />
                    </Grid>
                  </Grid>

                  {editFields.price && (
                    <>
                      <PriceTypeSelector
                        value={selectedProduct.ebay_sync_data.priceType}
                        onChange={handlePriceTypeChange}
                      />
                      <TextField
                        fullWidth
                        name={selectedProduct.ebay_sync_data.priceType == 'normal' ? 'price' : 'percentage'}
                        label={selectedProduct.ebay_sync_data.priceType == 'normal' ? 'Price' : 'Percentage'}
                        value={selectedProduct.ebay_sync_data.priceType == 'normal' ? selectedProduct.ebay_sync_data.price : selectedProduct.ebay_sync_data.percentage}
                        onChange={(e) => {
                          if (priceRegex(e.target.value)) {
                            handleInputChange(e);
                          }
                        }}
                        placeholder={`Enter product price ${selectedProduct.ebay_sync_data.priceType === 'percentage' ? 'percentage' : ''}`}
                        className={`${styles.fieldStyles} ${styles.editProductPrices}`}
                        inputProps={{ maxLength: 6 }}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={6} className={styles.colRight}>
                  <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
                    <Grid item xs={6} className={styles.fullWidthOnMobile}>
                      <InputLabel><b><span className={styles.textRed}>*</span> Product Quantity: </b><span className={styles.lightText}>{selectedProduct.quantity}</span></InputLabel>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" className={styles.fullWidthOnMobile}>
                      <CustomSwitch
                        checked={editFields.quantity}
                        onChange={() => handleToggleField('quantity')}
                        label="Alternative Quantity"
                      />
                    </Grid>
                  </Grid>
                  {editFields.quantity && (
                    <TextField
                      fullWidth
                      name="quantity"
                      value={selectedProduct.ebay_sync_data.quantity}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (/^\d*$/.test(value)) {
                          handleInputChange(e);
                        }
                      }}
                      placeholder="Enter product quantity"
                      className={`${styles.fieldStyles} ${styles.editProductQuantity}`}
                      inputProps={{ maxLength: 6 }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderCombinations()}
              </Grid>
              <Grid item xs={12} className={styles.fieldsBox}>
                <Grid container alignItems="center" justifyContent="space-between" className={styles.switchRows}>
                  <Grid item xs={9} className={styles.fullWidthOnMobile}>
                    <InputLabel><b><span className={styles.textRed}>*</span> Product Description </b></InputLabel>
                  </Grid>
                  <Grid item xs={3} container justifyContent="flex-end" className={styles.fullWidthOnMobile}>
                    <CustomSwitch
                      checked={editFields.description}
                      onChange={() => handleToggleField('description')}
                      label="Alternative Description"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <div
                    dangerouslySetInnerHTML={{ __html: selectedProduct.description ?? 'N/A' }}
                  />
                  {editFields.description && (
                    <RichTextEditor
                      value={selectedProduct.ebay_sync_data.description}
                      onChange={handleEditorChange}
                    />
                  )}

                </Grid>
              </Grid>

              <Grid item xs={12} className={styles.editProductGallery}>
                {selectedProduct.galleryImages.length > 0 && (
                  <InputLabel>Product Gallery</InputLabel>
                )}

                <Grid container spacing={2} style={{ display: "flex", gap: "0px 10px", flexWrap: "wrap", margin: "0px" }}>
                  {selectedProduct.galleryImages.map((image, index) => (
                    <div key={index} style={{ display: 'inline-block', position: 'relative' }}>
                      <img src={image.url} alt={`Product ${index}`} style={{ width: '100px', height: '100px' }} />
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <DialogActions className={styles.modalBtnsRow}>
              <Button onClick={handleClose} className={styles.cancelBtns} variant="contained">Cancel</Button>
              <Button color="primary" disabled={!validateFields()} variant="contained" onClick={() => { isSync ? handleConfirmationOpen() : handleSubmit(new Event('submit')) }}>Save</Button>
            </DialogActions>
          </form>
          <div className={styles.closeModalBtn} onClick={handleClose}><ClearIcon /></div>
        </DialogContent>
      </Dialog>}

      {/** Confirmation Modal */}
      <Modal
        modalOpen={confirmationModal}
        modalHandleClose={handleConfirmationClose}
        title={
          <div className={styles.modalIconTitle}>
            <ErrorOutlineIcon />
            Are you sure?
          </div>
        }
        loading={loading}  // Pass a boolean value to control the loader
        sx={{
          "& .MuiDialogContent-root": {
            height: "80%",
          }
        }}
      >
        <DialogContent className={styles.confirmationContent}>
          <p className={styles.textCenter}>Are you sure you want to sync the selected product(s)?</p>
        </DialogContent>
        <DialogActions className={styles.confirmationBtns}>
          <Button variant="contained" className={styles.cancelBtns} onClick={handleConfirmationClose}>No</Button>

          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              Yes
            </Button>

            {loading && (
              <CircularProgress
                size={24} // Adjust size as needed
                className="circular-progress-button"
              />
            )}
          </div>
        </DialogActions>
      </Modal>
    </div>

  );
};

export default EditProductDialog;
