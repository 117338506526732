import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import styles from "./Notification.module.css";

const Notification = ({ message, severity, open, handleCloseNotification }) => {
  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseNotification} className={styles.alertBoxes}>
      <Alert severity={severity} sx={{ width: '100%' }} className={styles.alertMessages}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
