import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';

const useAuthRedirect = (delay = 1000) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Get user from AuthContext
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (user || token) {
      navigate('/');
    }
  }, [navigate, user]);
};

export default useAuthRedirect;
